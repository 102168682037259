import React, { FC, useState } from "react";
import { IMobileMenu } from "../types";
import { BurgerButton } from "./BurgerButton/BurgerButton";
import { SideMenu } from "./SideMenu/SideMenu";

// Styles
import style from "./MobileMenu.module.scss";
import cl from '@cncompiler';
const _c = cl(style);

export const MobileMenu: FC<IMobileMenu> = ({ className }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<div className={_c("MobileMenu", className || "")}>
			<BurgerButton isOpen={isMenuOpen} handleClick={() => setIsMenuOpen(prev => !prev)} className={_c("MobileMenu__Button")} />
			<SideMenu className={_c("MobileMenu__SideMenu")} isShow={isMenuOpen} handleIsOpen={() => setIsMenuOpen(prev => !prev)} />
		</div>
	);
};

