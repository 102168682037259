export enum IgetColorClass {
    Yellow_100 = 'Yellow_100',
    Yellow_500 = 'Yellow_500',
    Yellow_1000 = 'Yellow_1000',

    Black_700 = 'Black_700',
    Black_800 = 'Black_800',
    Black_900 = 'Black_900',
    Black_1000 = 'Black_1000',

    H1_1000 = 'H1_1000',
    H1_950 = 'H1_950',
    H1_850 = 'H1_850',

    Text_1000 = 'Text_1000',
    Text_950 = 'Text_950',
    Text_900 = 'Text_900',
    Text_850 = 'Text_850',

    Bg_900 = 'Bg_900',
    Bg_800 = 'Bg_800',

    White_1000 = 'White_1000',

	Error = 'Error',
}

export const getColorClass = (color: IgetColorClass) => `UI_Colors_${color}`