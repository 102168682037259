import React, { CSSProperties, ReactElement } from "react";
import cl from "@cncompiler";

import styles from './ContentArea.module.scss'
const _c = cl(styles)

export const ContentArea: React.FC<{
  children?: ReactElement | ReactElement[];
  className?: string;
  style?: CSSProperties
}> = ({ children, className, style }) => {
  return (
        <div style={style} className={_c('Content_Area', `${className ? className : ""}`)}>
          {children}
        </div>
  )
}
