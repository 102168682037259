import React, { FC } from "react";
import { IDesktopMenu } from "../types";
import { content } from "../scheme";
import { Item } from "../Item/Item";
import { routes } from "@config/routes";

// Styles
import style from "./DesktopMenu.module.scss";
import cl from '@cncompiler';
const _c = cl(style);


export const DesktopMenu: FC<IDesktopMenu> = ({ className }) => {

	return (
		<div className={_c("DesktopMenu", className || "")}>
			<Item className={_c("DesktopMenu__Item")} redirect={`#${content.ids.aboutUs}`} text={content.links.aboutUs} />
			<Item className={_c("DesktopMenu__Item")} redirect={`#${content.ids.forWhom}`} text={content.links.forWhom} />
			<Item className={_c("DesktopMenu__Item")} redirect={`#${content.ids.directions}`} text={content.links.directions} />
			<Item className={_c("DesktopMenu__Item")} redirect={`#${content.ids.results}`} text={content.links.results} />
		</div>
	);
};