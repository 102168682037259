import React, { FC } from "react";
import { IFooter } from "../../../types";
import { content } from "../../../scheme";
import { ITypographyStyle, ITypographyTag, IgetColorClass, Typography } from "@components/Typography/Typography";

// Styles
import style from "./Footer.module.scss";
import cl from '@cncompiler';
const _c = cl(style);

export const Footer: FC<IFooter> = ({}) => {
	const { address, labelAddress, labelConnect, phone, email} = content.information;

	return (
		<div className={_c("Footer")}>
			<Typography className={_c("Footer__LabelAddress")} style={ITypographyStyle.O1} text={labelAddress} color={IgetColorClass.H1_950} tag={ITypographyTag.p} />
			<Typography className={_c("Footer__Address")} style={ITypographyStyle.C3} text={address} color={IgetColorClass.White_1000} tag={ITypographyTag.address} />
			<Typography className={_c("Footer__LabelConnect")} style={ITypographyStyle.O1} text={labelConnect} color={IgetColorClass.H1_950} tag={ITypographyTag.p} />
			<a className={_c("Footer__Phone")} href={`tel:${phone}`}>
				<Typography style={ITypographyStyle.C3} text={phone} color={IgetColorClass.White_1000} tag={ITypographyTag.p} />
			</a>
			<a className={_c("Footer__Email")} href={`mailto:${email}`}>
				<Typography style={ITypographyStyle.C3} text={email} color={IgetColorClass.White_1000} tag={ITypographyTag.p} />
			</a>
		</div>
	);
};
