import React, { FC } from "react";
import { IMenu } from "./types";
import { DesktopMenu } from "./DesktopMenu/DesktopMenu";
import { MobileMenu } from "./MobileMenu/MobileMenu";

// Styles
import style from "./Menu.module.scss";
import cl from '@cncompiler';
const _c = cl(style);


export const Menu: FC<IMenu> = ({ className }) => {
	return (
		<div className={_c("Menu", className || "")}>
			<DesktopMenu className={_c("Menu__Desktop")} />
			<MobileMenu className={_c("Menu__Mobile")} />
		</div>
	);
};

