import React, { useEffect, useState } from 'react';

const MOBILE_MAX = 767;
const MOBILE_MIN = 425;
const TABLET_MAX = 1023;
const TABLET_MIN = 768;
const DESKTOP_MIN = 1024;

export interface Views {
	mobileMax: number,
	mobileMin: number,
	tabletMax: number,
	tabletMin: number,
	desktopMin: number,
}

export const useGetScreenWidth = () => {
	const views: Views = {
		mobileMax: MOBILE_MAX,
		mobileMin: MOBILE_MIN,
		tabletMax: TABLET_MAX,
		tabletMin: TABLET_MIN,
		desktopMin: DESKTOP_MIN,
	};

	const [screenWidth, setScreenWidth] = useState(0);

	useEffect(() => {
		setScreenWidth(window.innerWidth);
	}, [])

	useEffect(() => {
		const handleResize = () => setScreenWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return { screenWidth, views };
}
