import React, { FC } from "react";
import { IBurgerButton } from "../../types";
import { content } from "../../scheme";
import { useGetScreenWidth } from "@hooks/useGetScreenWidth";
import { Icon } from "./Icon/Icon";

// Styles
import style from "./BurgerButton.module.scss";
import cl from '@cncompiler';
const _c = cl(style);


export const BurgerButton: FC<IBurgerButton> = ({ className, handleClick, isOpen }) => {
	const { screenWidth, views: { mobileMax } } = useGetScreenWidth();

	return (
		<button onClick={handleClick} className={_c("BurgerButton", className || "")}>
			<Icon isShow={isOpen} />
			{screenWidth > mobileMax && <p className={_c(`BurgerButton__Text`)}>{content.menu}</p>}
		</button>
	);
};
