import React, { FC } from 'react';
import { ContentArea } from "@components/ContentArea/ContentArea";
import { IgetColorClass, ITypographyStyle, ITypographyTag, Typography } from "@components/Typography/Typography";
import { IDirections } from './types';
import { Button } from "@components/Button/Button"
import { content } from './scheme';
import { BlockTitle } from '@components/BlockTitle/BlockTitle';


// Styles
import style from './Directions.module.scss';
import cl from "@cncompiler";
import { BlockWithTitle } from '@components/BlockWithTitle/BlockWithTitle';
const _c = cl(style)


export const Directions: FC<IDirections> = ({ className }) => {
	return (
		<ContentArea className={_c(`Directions`, `${className || ""}`)}>
			<BlockTitle className={_c("Directions__Title")} title={content.title} />
			<div id={content.id} className={_c("Directions__Items")}>
				{content.items.map((item, index) => <BlockWithTitle key={`Item${index}`} title={item.title} subtitle={item.subtitle}/>)}
			</div>
	  </ContentArea>
	);
}

