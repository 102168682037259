import React, { FC } from 'react';
import { ContentArea } from "@components/ContentArea/ContentArea";
import { IgetColorClass, ITypographyStyle, ITypographyTag, Typography } from "@components/Typography/Typography";
import { IBlockWithTitle } from './types';
import { Button } from "@components/Button/Button"



// Styles
import style from './BlockWithTitle.module.scss';
import cl from "@cncompiler";
const _c = cl(style)


export const BlockWithTitle: FC<IBlockWithTitle> = ({ className, title, subtitle }) => {
	return (
		<div className={_c(`BlockWithTitle` , `${className || ""}`)}>
			<Typography className={_c("BlockWithTitle__Title")} text={title} style = {ITypographyStyle.C3} color ={IgetColorClass.Text_900} />
			<Typography className={_c("BlockWithTitle__Subtitle")} text={subtitle} style = {ITypographyStyle.C3} color ={IgetColorClass.Text_900} />
		</div>
	);
}
