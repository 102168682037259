import React, { FC, useState } from "react";
import { ILinks } from "../../../types";
import { Item } from "../../../Item/Item";
import { routes } from "@config/routes";
import { content } from "../../../scheme";

// Styles
import style from "./Links.module.scss";
import cl from '@cncompiler';
const _c = cl(style);


export const Links: FC<ILinks> = ({ className, handleIsOpen }) => {
	return (
		<div className={_c("Links", className || "")}>
			<Item className={_c("Links__Item")} handleClick={handleIsOpen} redirect={`#${content.ids.aboutUs}`} text={content.links.aboutUs} />
			<Item className={_c("Links__Item")} handleClick={handleIsOpen} redirect={`#${content.ids.forWhom}`} text={content.links.forWhom} />
			<Item className={_c("Links__Item")} handleClick={handleIsOpen} redirect={`#${content.ids.directions}`} text={content.links.directions} />
			<Item className={_c("Links__Item")} handleClick={handleIsOpen} redirect={`#${content.ids.results}`} text={content.links.results} />
		</div>
	);
};