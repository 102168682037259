import React, { FC, useEffect, useState } from 'react';
import styles from './App.module.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { routes } from '@routes';
import Main from '@pages/Main';
import Header from '@containers/Header';
import Footer from '@containers/Footer';

export const App = ({}) => {
	// const [isValid, setIsValid] = useState(false);
	// const VALIDLOGIN = 'medai'

	// useEffect(() => {
	// 	if (localStorage.getItem("login") === VALIDLOGIN) {
	// 		setIsValid(true);
	// 	} else  {
	// 		while (true) {
	// 			const answer = prompt('Введите кодовое слово для доступа');
	// 			console.log('answer: ', answer)
	// 			console.log('validword: ', VALIDLOGIN)

	// 			if (answer === VALIDLOGIN) {
	// 				localStorage.setItem("login", answer);
	// 				setIsValid(true);
	// 				break ;
	// 			}
	// 		}
	// 	}
	// }, [])

	return (
		<>
		{/* { isValid && */}
			<div className={styles.app}>
				<Header />
				<Main />
				<Footer />
			</div>
		{/* } */}
		</>
	);
}
