import React, { FC } from 'react';
import { ContentArea } from "@components/ContentArea/ContentArea";
import { IResults } from './types';
import { content } from './scheme';
import { IgetColorClass, ITypographyStyle, ITypographyTag, Typography } from "@components/Typography/Typography";
import { BlockTitle } from '@components/BlockTitle/BlockTitle';
import { BlockWithTitle } from '@components/BlockWithTitle/BlockWithTitle';


// Styles
import style from './Results.module.scss';
import cl from "@cncompiler";
const _c = cl(style);


export const Results: FC<IResults> = ({ className }) => {
	return (
		<ContentArea className={_c(`Results`, `${className || ""}`)}>
			<BlockTitle className={_c("Results__Title")} title={content.title} />
			<div id={content.id} className={_c("Results__Items")}>
				{content.items.map((item, index) => {
					return (
						<div className={_c("Results__Item")} key={`Item${index}`}>
							<Typography className={_c("Results__ItemTitle")} color={IgetColorClass.Text_900} options={{ToUpperCase: true}} text={item.title} style={ITypographyStyle.B3} tag={ITypographyTag.p} />
							<Typography className={_c("Results__ItemSubtitle")} color={IgetColorClass.Text_900} options={{ToUpperCase: true}} text={item.subtitle} style={ITypographyStyle.B3} tag={ITypographyTag.p} />
						</div>
					);
				})}
			</div>
	  </ContentArea>
	);
}

