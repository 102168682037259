import React from "react";
import { IgetColorClass, getColorClass } from "@config/colors";
import style from './Typography.module.scss'
import cl from "@cncompiler";

const _c = cl(style)

export enum ITypographyStyle {
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3',
    Button1 = 'Button1',
    Button2 = 'Button2',
    Input1 = 'Input1',
    Input2 = 'Input2',
    O1 = 'O1',
    O2 = 'O2',
    O3 = 'O3',
    O4 = 'O4',
    H1 = 'H1',
    H2 = 'H2',
    S1 = 'S1',
    S2 = 'S2',
    S3 = 'S3',
    S4 = 'S4',
    S5 = 'S5',
    P1 = 'P1',
    P2 = 'P2',
    P3 = 'P3',
    P4 = 'P4',
    B1 = 'B1',
    B2 = 'B2',
    B3 = 'B3',
    Tag1 = 'Tag1',
    MARCDOWN_NEWS = 'MARCDOWN_NEWS',
}

export enum ITypographyTag {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    p = 'p',
    q = 'q',
	label = 'label',
    address = 'address',
    blockquote = 'blockquote',
	span = 'span'
}

export const ITypographyStyleGetClass = (style: ITypographyStyle) => `UI_Typography UI_Typography_${ITypographyStyle[style]}`
export const ITypographyStyleGetClassFull = ({
    style,
    color,
    options,
    className,
} : Omit<ITypography, 'text'>) => {
    const classes = [`UI_Typography`, `UI_Typography_${ITypographyStyle[style]}`]

    if(options) {
        const {
            lineClampRowsCount,
            WSPreline,
            ForBaner,
            ToUpperCase,
            Inline,
            TextAlignCenter,
        } = options

        if(lineClampRowsCount) classes.push(`UI_Typography_LineClamp-${lineClampRowsCount}`)
        if(WSPreline) classes.push(`UI_Typography_WSPreline`)
        if(ForBaner) classes.push(`UI_Typography_ForBaner`)
        if(ToUpperCase) classes.push(`UI_Typography_ToUpperCase`)
        if(Inline) classes.push(`UI_Typography_Inline`)
        if(TextAlignCenter) classes.push('UI_Typography_TextAlignCenter')
    }
    if(color) classes.push(getColorClass(color))

    const addClasses = className && typeof className === 'string' ? className : ''
    return _c(classes, addClasses)
}

type ITypographyOptions = {
    lineClampRowsCount?: number,
    WSPreline?: boolean,
    ForBaner?: boolean,
    ToUpperCase?: boolean,
    Inline?: boolean,
    TextAlignCenter?: boolean,
}

export interface ITypography {
    text: string | string[],
    style: ITypographyStyle,
    tag?: ITypographyTag,
    color?: IgetColorClass,
    options?: ITypographyOptions,
    className?: string,
    protect?: boolean,
    linkPath?: string, 
	itemProp?: string,
}

/**
 * 
 * @param linkPath - при передаче оргумента возвращает ссылку
 * @returns 
 */
export const Typography: React.FC <ITypography> = ({
    text,
    style,
    tag,
    color,
    options,
    className,
    protect,
    linkPath,
	itemProp
}) => {
    const Tag = tag ? tag as keyof JSX.IntrinsicElements : `div`;
    const classCombine = ITypographyStyleGetClassFull({
        style,
        tag,
        color,
        options,
        className,
        protect,
        linkPath,
    })

    // const classCombine = () => {
    //     const classes = [`UI_Typography`, `UI_Typography_${ITypographyStyle[style]}`]

    //     // if(className && typeof className === 'string') classes.push(className)
    //     if(options) {
    //         const {
    //             lineClampRowsCount,
    //             WSPreline,
    //             ForBaner,
    //             ToUpperCase,
    //             Inline,
    //             TextAlignCenter,
    //         } = options

    //         if(lineClampRowsCount) classes.push(`UI_Typography_LineClamp-${lineClampRowsCount}`)
    //         if(WSPreline) classes.push(`UI_Typography_WSPreline`)
    //         if(ForBaner) classes.push(`UI_Typography_ForBaner`)
    //         if(ToUpperCase) classes.push(`UI_Typography_ToUpperCase`)
    //         if(Inline) classes.push(`UI_Typography_Inline`)
    //         if(TextAlignCenter) classes.push('UI_Typography_TextAlignCenter')
    //     }
    //     if(color) classes.push(getColorClass(color))

    //     const addClasses = className && typeof className === 'string' ? className : ''
    //     return _c(classes, addClasses)
    // }
    
    if(!text) return null

    return (
        linkPath 
            ? 
            <div className={_c('UI_Typography_ProtectWrapper')}>
                <a 
                    className={classCombine}
                    href={linkPath}
                >
                    {typeof text === 'object'
                        // ? text.map((e, i) => <div key={`UI_Typography_Key_${i}`}>{e}</div>)
                        ? <>{text.join('\n')}</>
                        : <>{text}</>
                    }
                    
                </a>
                {protect && <div className={_c('UI_Typography_Protect')}/>}
            </div>
            : <Tag {...(itemProp ? { itemProp: itemProp } : {})} className={classCombine} >
                {typeof text === 'object'
                    // ? text.map((e, i) => <div key={`UI_Typography_Key_${i}`}>{e}</div>)
                    ? <>{text.join('\n')}</>
                    : <>{text}</>
                }
                {protect && <div className={_c('UI_Typography_Protect')}/>}
            </Tag>
    )
}
    
export {IgetColorClass};