import React, { FC } from 'react';
import { IMain } from './types';
import { AboutUs } from '@containers/AboutUs/AboutUs';
import { ForWhom } from '@containers/ForWhom/ForWhom';
import { Results } from '@containers/Results/Results';


// Styles
import styles from './Main.module.scss';
import cl from '@cncompiler';
import { Directions } from '@containers/Directions/Directions';
const _c = cl(styles);

export const Main: FC<IMain> = ({}) => {

	return (
		<main className={_c("Main")}>
			<AboutUs className={_c("Main__AboutUs")} />
			<ForWhom className={_c("Main__ForWhom")} />
			<Directions	className={_c("Main__Directions")} />
			<Results className={_c("Main__Results")} />
		</main>
	);
}
