import React, { FC } from 'react';
import { IFooter } from './types';
import { content } from './scheme';
import { Typography, ITypographyStyle, ITypographyTag, IgetColorClass } from '@components/Typography/Typography';

// Styles
import styles from './Footer.module.scss';
import cl from '@cncompiler';
const _c = cl(styles);

export const Footer: FC<IFooter> = ({}) => {
	return (
		<footer className={_c("Footer")}>
			<Typography className={_c('Footer__Title')} tag={ITypographyTag.h4} style={ITypographyStyle.C2} text={content.title}/>
			<Typography className={_c('Footer__Item Footer__Address')} tag={ITypographyTag.address} style={ITypographyStyle.C1} text={content.address}/>
			<a className={_c('Footer__Item Footer__Phone')} href={`tel:+7${content.phone.substring(1)}`}>
				<Typography tag={ITypographyTag.p} style={ITypographyStyle.C1} text={content.phone}/>
			</a>
			<a className={_c('Footer__Item Footer__Email')} href={`mailto:${content.email}`}>
				<Typography tag={ITypographyTag.p} style={ITypographyStyle.C1} text={content.email}/>
			</a>
			<Typography className={_c('Footer__Item Footer__Item-additional Footer__NameCompany')} tag={ITypographyTag.p} style={ITypographyStyle.O4} text={content.companyName} color={IgetColorClass.Text_1000}/>
			<Typography className={_c('Footer__Item Footer__Item-additional Footer__Inn')} tag={ITypographyTag.p} style={ITypographyStyle.O4} text={content.inn} color={IgetColorClass.Text_1000}/>
			<Typography className={_c('Footer__Item Footer__Item-additional Footer__Ogrn')} tag={ITypographyTag.p} style={ITypographyStyle.O4} text={content.ogrn} color={IgetColorClass.Text_1000}/>
		</footer>
	);
}