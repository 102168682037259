export const content = {
	links : {
		aboutUs: `О нас`,
		forWhom: `Для кого`,
		directions: `Направления`,
		results: `Результаты`
	},
	menu: 'Меню',
	information: {
		labelAddress: 'Мы находимся по адресу:',
		address: '127083, г. Москва, вн.тер.г. Муниципальный округ Савеловский, ул. 8 Марта, д. 10, стр. 6',
		labelConnect: 'Связаться с нами',
		phone: '8 900 000-00-00',
		email: 'info@info.ru',
	},
	ids: {
		aboutUs: 'about-us',
		forWhom: 'for-whom',
		directions: 'directions',
		results: 'results'
	}
}
