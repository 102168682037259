import React, { FC } from "react";

import { ISideMenu } from "../../types";
import { Links } from "./Links/Links";
import { Footer } from "./Footer/Footer";

// Styles
import style from "./SideMenu.module.scss";
import cl from '@cncompiler';
const _c = cl(style);


export const SideMenu: FC<ISideMenu> = ({ className, isShow, handleIsOpen }) => {
	return (
		<aside className={_c(`SideMenu ${isShow ? "SideMenu-Shown" : ""}`, className || "")}>
			<Links className={_c("SideMenu__Links")} handleIsOpen={handleIsOpen} />
			<Footer className={_c("SideMenu__Footer")} />
		</aside>
	);
};
