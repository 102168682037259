import React, { FC } from "react";
import { useGetScreenWidth } from "@hooks/useGetScreenWidth";

// Styles
import style from "./Icon.module.scss";
import cl from '@cncompiler';
const _c = cl(style);


export const Icon: FC<{ isShow: boolean }> = ({ isShow }) => {
	const { screenWidth, views } = useGetScreenWidth();

	return (
		<>
			<svg className={_c(`Icon__Burger ${isShow ? "Icon__Burger-active" : ""}`)} width={screenWidth > views.mobileMax ? "19" : "26"} height={screenWidth > views.mobileMax ? "18" : "26"} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M4.33398 4.3335L22.3804 22.3799M22.3804 4.3335L4.33398 22.3799" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
			<svg className={_c(`Icon__Close ${!isShow ? "Icon__Close-active" : ""}`)}  width={screenWidth > views.mobileMax ? "19" : "26"} height={screenWidth > views.mobileMax ? "18" : "26"} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M2.77148 3.75H16.2715" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M2.77148 9H16.2715" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M2.77148 14.25H16.2715" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
		</>
	);
}