export const content = {
	title: 'Кому подойдут наши решения',
	id: 'for-whom',
	items: [
		{
			title: 'Государственные медорганизации',
			subtitle: 'Повышение качества работы лучевой диагностики и минимизация ошибок'
		},
		{
			title: 'Коммерческие клиники',
			subtitle: 'Дополнительная информация для проведения диагностических мероприятий и повышения качества услуг'
		},
		{
			title: 'Фармацевтические компании и лаборатории',
			subtitle: 'Уменьшение нагрузки во время клинических испытаний и оптимизации расходов при производстве препаратов'
		}
	]
}