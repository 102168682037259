import React, { FC } from 'react';
import { IgetColorClass, ITypographyStyle, ITypographyTag, Typography } from "@components/Typography/Typography";
import { IBlockTitle } from './types';

// Styles
import style from './BlockTitle.module.scss';
import cl from "@cncompiler";
const _c = cl(style)


export const BlockTitle:FC<IBlockTitle> = ({ className, title }) => {
	return (
		<Typography className={_c("BlockTitle", className || "")} options={{ToUpperCase: true}} text={title} style={ITypographyStyle.S3} tag={ITypographyTag.h2} />
	);
}