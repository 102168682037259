import React, { FC } from "react";
import { ILogo } from "./types";
import { useGetScreenWidth } from "@hooks/useGetScreenWidth";
import { routes } from "@routes";
import { content } from "./scheme";
import medAiLogo from './medAiLogo.svg'
import medAiLogoM from './medAiLogoM.svg'

// Styles
import style from "./Logo.module.scss";
import cl from '@cncompiler';
const _c = cl(style);


export const Logo: FC<ILogo> = ({ className }) => {
	const {screenWidth, views: { mobileMax }} = useGetScreenWidth();
	return (
		<a className={_c("Logo", className || "")} href={routes.home}>
			<img className={_c(`Logo__Image`)} src={`${screenWidth <= mobileMax ? medAiLogoM : medAiLogo}`} alt={content.altLogo} />
		</a>
	);
}
