export const content = {
	title: 'Результаты, которые уже получили',
	id: 'results',
	items: [
		{
			title: 'Определение вероятности травмы тазобедренного сустава по МРТ-снимкам',
			subtitle: 'С точностью более  80% научились предсказывать вероятность развития патологий тазобедренного сустава – синовита и повреждения хряща'
		},
		{
			title: 'Морфометрический анализ гистологических препаратов. Сегментация',
			subtitle: 'Технология позволяет экономить время дорогих специалистов и сокращать время ожидания заключения. Система сегментирует биопсию почки с точностью 92% и биопсию толстой кишки с точностью 99%'
		},
		{
			title: 'Морфометрический анализ гистологических препаратов. Анализ',
			subtitle: 'В трансплантологии ИИ-анализ способен предоставить врачам возможность принять решение по принятию/отторжению органа, основываясь в том числе на результате анализа данных'
		},
	]
}