import React, { FC } from 'react';
import { IAboutUs } from './types';
import ContentArea from '@components/ContentArea';
import { ITypographyStyle, ITypographyTag, IgetColorClass, Typography } from "@components/Typography/Typography";
import { content } from './scheme';

// Styles
import styles from './AboutUs.module.scss';
import cl from '@cncompiler';
const _c = cl(styles);

export const AboutUs: FC<IAboutUs> = ({ className }) => {

	return (
		<ContentArea className={_c(`AboutUs`, `${className || ""}`)}>
			<div id={content.id} className={_c("AboutUs__Box")}>
				<Typography className={_c('AboutUs__Text')} style={ITypographyStyle.B1} text={content.title} tag={ITypographyTag.p} color={IgetColorClass.Text_900} options={{WSPreline: true}} />
			</div>
		</ContentArea>
	);
}
