export const content = {
	title: 'Приоритетные направления развития',
	id: 'directions',
	items: [
		{
			title: 'Цифровая лаборатория ',
			subtitle: 'Облачная платформа — основа для взаимодействия ученых и IT-специалистов в создании цифровых решений с использованием искусственного интеллекта.'
		},
		{
			title: 'Системы поддержки принятия врачебных решений (СППВР)',
			subtitle: 'Формируемые в цифровой лаборатории датасеты служат основой для обучения нейронных сетей, которые способны помочь врачам снизить риск ошибки и начать лечение на более ранних стадиях'
		},
		{
			title: 'Цифровая патоморфология',
			subtitle: 'Современные технологии позволяют сканировать и оцифровывать гистологические снимки. Решения на основе ИИ помогают существенно снизить временные затраты на анализ состава клеток и классификацию ткани'
		},
		{
			title: 'Machine Learning as a Service',
			subtitle: 'Создание уникальных решения в здравоохранении, которые могут помочь обеспечить рост эффективности за счёт внедрения ИИ в работу медицинских организаций'
		}
	]
}