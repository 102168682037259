class ClassNameCompiler {
    style: any
    
    constructor(style: any) {
        this.style = style
    }

    getClassName(str: string | string[], strPlus?: string | string[]){
        const names = typeof str === 'string' ? [...str.split(' ')] : str;
        const namesPlus = typeof strPlus === 'string' ? [...strPlus.split(' ')] : strPlus;
        return `${names.filter(a => a !== '' ).reduce((a,b) => `${a}${this.style[b]} `, '')} ${namesPlus ? namesPlus.join(' ') : ''}`
    }
}

export default function cl (style: any) {
    const classN = new ClassNameCompiler(style)
    const fBind = classN.getClassName.bind(classN)
    return fBind
}