import React, { FC } from "react";
import { Typography, ITypographyStyle, ITypographyTag } from "@components/Typography/Typography";
import { IItem } from "../types";

// Styles
import style from "./Item.module.scss";
import cl from '@cncompiler';
const _c = cl(style);

export const Item: FC<IItem> = ({ className, redirect = "", text, Icon, handleClick, isActive }) => {

	return (
		<a onClick={handleClick} href={redirect} className={_c(`Item ${isActive ? "Item-active" : ""}`, className || "")}>
			<Typography
            	className={_c('Item__Text')}
              	style={ITypographyStyle.C1}
              	text={text}
              	tag={ITypographyTag.p}
            />
			{Icon && Icon}
		</a>
	);
};