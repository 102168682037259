import React, { FC } from 'react';
import Logo from './Logo';
import Menu from './Menu';
import { IHeader } from './types';

// Styles
import styles from './Header.module.scss';
import cl from '@cncompiler';
const _c = cl(styles);

export const Header: FC<IHeader> = ({}) => {
	return (
		<header className={_c("Header")}>
			<Logo className={_c("Header__Logo")} />
			<Menu className={_c("Header__Menu")} />
		</header>
	);
}